
  .clearfix:after {
    content: "";
    display: table;
    clear: both;
  }
  
  a {
    color: #0087C3;
    text-decoration: none;
  }
  
  
  
  #logo {
    float: left;
    margin-top: 8px;
  }
  
  #logo img {
    height: 140px;
  }
  
  #company {
    float: right;
    text-align: right;
  }
  
  
  #details {
    margin-bottom: 50px;
  }
  
  #client {
    padding-left: 6px;
    border-left: 6px solid #0087C3;
    float: left;
  }
  
  #client .to {
    color: #777777;
  }
  
  .doctor {
    padding-right: 6px;
    border-right: 6px solid #0087C3;
    float: right;
  }
  
  .doctor .to {
    color: #777777;
  }
  
  
  h2.name {
    font-size: 1.4em;
    font-weight: normal;
    margin: 0;
  }
  
  #invoice {
    float: right;
    text-align: right;
  }
  
  #invoice h1 {
    color: #0087C3;
    font-size: 2.4em;
    line-height: 1em;
    font-weight: normal;
    margin: 0  0 10px 0;
  }
  
  #invoice .date {
    font-size: 1.1em;
    color: #777777;
  }
  
  .pdf-table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    margin-bottom: 20px;
  }
  
  .pdf-table th,
  .pdf-table td {
    padding: 20px;
    background: #EEEEEE;
    text-align: center;
    border-bottom: 1px solid #FFFFFF;
  }
  
  .pdf-table th {
    white-space: nowrap;        
    font-weight: normal;
  }
  
  .pdf-table td {
    text-align: right;
  }
  
  .pdf-table td h3{
    color: #57B223;
    font-size: 1.2em;
    font-weight: normal;
    margin: 0 0 0.2em 0;
  }
  
  .pdf-table .no {
    color: #FFFFFF;
    font-size: 1.6em;
    background: #57B223;
  }
  
  .pdf-table .desc {
    text-align: left;
  }
  
  .pdf-table .unit {
    background: #DDDDDD;
  }
  
  .pdf-table .qty {
  }
  
  .pdf-table .total {
    background: #57B223;
    color: #FFFFFF;
  }
  
  .pdf-table td.unit,
  .pdf-table td.qty,
  .pdf-table td.total {
    font-size: 1.2em;
  }
  
  .pdf-table tbody tr:last-child td {
    border: none;
  }
  
  .pdf-table tfoot td {
    padding: 10px 20px;
    background: #FFFFFF;
    border-bottom: none;
    font-size: 1.2em;
    white-space: nowrap; 
    border-top: 1px solid #AAAAAA; 
  }
  
  .pdf-table tfoot tr:first-child td {
    border-top: none; 
  }
  
  table tfoot tr:last-child td {
    color: #57B223;
    font-size: 1.4em;
    border-top: 1px solid #57B223; 
  
  }
  
  table tfoot tr td:first-child {
    border: none;
  }
  
  #thanks{
    font-size: 2em;
    margin-bottom: 50px;
  }
  
  #notices{
    padding-left: 6px;
    border-left: 6px solid #0087C3;  
  }
  
  #notices .notice {
    font-size: 1.2em;
  }
  
  footer {
    color: #777777;
    width: 100%;
    height: 30px;
    /* position: absolute; */
    bottom: 0;
    border-top: 1px solid #AAAAAA;
    padding: 8px 0;
    text-align: center;
  }
  